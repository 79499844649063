<template>
    <div class="vue-tempalte">
        <form @submit.prevent="login">
            <h3>Sign In</h3>
			<p v-if="msg">{{msg}}</p>
            <div class="form-group">
                <label>Username</label>
                <input v-model="username" name="username" type="text" class="form-control form-control-lg" :class="{ 'is-invalid': submitted && !username }" />
            </div>

            <div class="form-group">
                <label>Password</label>
                <input v-model="password" name="password" type="password" class="form-control form-control-lg" :class="{ 'is-invalid': submitted && !password }" />
            </div>

            <button type="submit" class="btn btn-dark btn-lg btn-block">Sign In</button>           
			
			<div v-if="loggingIn" class="container-loading">
				<p>Please wait...</p>
			</div>
            
        </form>
    </div>
</template>
<style>
	form {
		padding: 60px;
	}
</style>
<script>
export default {
  data () {
    return {
		username: '',
		password: '',
		msg: '',
		submitted: false,
		loggingIn: false,
    }
  },

  methods: {
    login () {
		this.submitted = true;		
		if(this.username && this.password) {
			this.loggingIn = true;
			this.$store
			.dispatch('login', {
				username: this.username,
				password: this.password
			})
			.then(() => {
				this.loggingIn = false,
				this.$router.push({ name: 'Dashboard' })
			})
			.catch(err => {
				this.loggingIn = false,
				this.msg = "Username or Password is invalid."
				console.log(err)
			})
		}
    }
  }
}
</script>